const carrierLogoLink = "https://storage.googleapis.com/leanbiz-core/static/carriers-logo";

export const CARRIER_ICONS = {
  DEFAULT_CARRIER: `${carrierLogoLink}/default_carrier.png`,

  "Flash express": `${carrierLogoLink}/flash_express.png`,
  "Inter express": `${carrierLogoLink}/inter_express.png`,
  "J&T": `${carrierLogoLink}/j_and_t.png`,
  "Kerry drop off": `${carrierLogoLink}/kerry.png`,
  "Kerry pickup": `${carrierLogoLink}/kerry.png`,
  "NIM express chilled": `${carrierLogoLink}/nim.png`,
  "NIM express": `${carrierLogoLink}/nim.png`,
  "Porlor express": `${carrierLogoLink}/porlor.png`,
  "SCG yamato express": `${carrierLogoLink}/scg_express.png`,
  "Thaipost-EMS": `${carrierLogoLink}/thaipost_ems.png`,
  "Thaipost-Register": `${carrierLogoLink}/thaipost.png`,
  "Thaipost-MyExpress": `${carrierLogoLink}/thaipost_ems.png`,
  "Xpresso": `${carrierLogoLink}/xpresso.png`,
  DHL: `${carrierLogoLink}/dhl.png`,
  Kerry: `${carrierLogoLink}/kerry.png`,
  LALAMOVE: `${carrierLogoLink}/lalamove.png`,
  NINJAVAN: `${carrierLogoLink}/ninjavan.png`,
  Sendit: `${carrierLogoLink}/sendit.png`,
  TNT: `${carrierLogoLink}/tnt.png`,
  ZTO: `${carrierLogoLink}/zto.png`,

  // icon from shippop
  "BEE express": `${carrierLogoLink}/bee_express.png`,
  "BEST express": `${carrierLogoLink}/best_express.png`,
  "CJ logistics": `${carrierLogoLink}/cj_logistics.png`,
  "J&T drop off": `${carrierLogoLink}/j_and_t.png`,
  "J&T pickup": `${carrierLogoLink}/j_and_t.png`,
  "JWD express chilled": `${carrierLogoLink}/jwd_express.png`,
  "JWD express frozen": `${carrierLogoLink}/jwd_express.png`,
  "JWD express": `${carrierLogoLink}/jwd_express.png`,
  "Niko logistic": `${carrierLogoLink}/niko_logistics.png`,
  "SCG yamato express chilled": `${carrierLogoLink}/scg_express_chilled_and_frozen.png`,
  "SCG yamato express frozen": `${carrierLogoLink}/scg_express_chilled_and_frozen.png`,
  "True E logistics": `${carrierLogoLink}/true_e_logistics.png`,
  Alphafast: `${carrierLogoLink}/alphafast.png`,
  Aramex: `${carrierLogoLink}/aramex.png`,
  Ninjavan: `${carrierLogoLink}/ninjavan.png`,
  Popman: `${carrierLogoLink}/popman.png`,
};
